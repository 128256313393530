/*
 * @Author: wangjie
 * @Date: 2022-03-31 09:31:51
 * @Description: 智慧复议Api
 */
import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = { reconsideration: '/data/reconsideration/reconsideration.json' }
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    reconsideration: '/reconsideration/reconsideration'
  }
}
export default api
