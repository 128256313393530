/*
 * @Author: zhaohongyang
 * @Date: 2022-03-02 10:48:41
 * @Description: 文件描述
 */
import { App } from 'vue'
import registerElement from './RegisterElement'
export function globalRegister(app: App): void {
  app.use(registerElement)
}

// declare global {
//   interface Window {
//     jspdf: any;//全局变量名
//   }
// }