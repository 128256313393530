/*
 * @Author: zhaohongyang
 * @Date: 2022-03-02 10:35:31
 * @Description: 文件描述
 */
import { IComObj } from '@/global/types'

/** 批量导入模块
 * @desc 导入指定文件夹下所有组件，合并返回
 * @param allComponents webkit自带require.context()导入文件方法
 * @param configs       用以判断合并后的类型，传入{}使用Object.assign方法拼接，[]使用concat方法拼接
 * @example const configApi = useMergeFile(require.context('.', true, /\.ts$/), {});
 */

export function useMergeFile(allComponents: any, configs: any) {
  if (Array.isArray(configs)) {
    allComponents.keys().forEach((key: any) => {
      if (key.indexOf('/index') > -1) return
      configs = configs.concat(allComponents(key).default)
    })
  } else if (typeof configs === 'object') {
    allComponents.keys().forEach((key: any) => {
      if (key.indexOf('/index') > -1) return
      Object.assign(configs, allComponents(key).default)
    })
  }
  return configs
}

// // 在目标对象数组中找到指定对象并精准删除，返回数组
// export function deleteAccurate(targetArr: IComObj[], fn: any) {
//   targetArr.splice(targetArr.findIndex(fn), 1)
//   return targetArr
// }
// 在目标对象数组中找到指定对象并精准删除，返回数组
export function deleteAccurate(targetArr: IComObj[], item: IComObj) {
  targetArr.splice(
    targetArr.findIndex(
      (i: IComObj) => item.id === i.id && item.type === i.type
    ),
    1
  )
  return targetArr
}