import type { AxiosInstance } from 'axios'
import axios from 'axios'
import type { IHttpRequestConfig, IRequestInterceptors } from './type'
import qs from 'qs'
import api from '@/api'
// import { ElLoading } from 'element-plus/lib/components'
import { LoadingInstance } from 'element-plus/lib/components/loading/src/loading.js'
import { ElMessage } from 'element-plus/lib/components'
import router from '@/router'
import { decryptParams } from '@/views/Login/encryptUtil.js'

const DEFAULT_LOADING = true

class httpRequest {
  instance: AxiosInstance
  interceptors?: IRequestInterceptors
  showLoading: boolean
  loading?: LoadingInstance

  constructor(config: IHttpRequestConfig) {
    // 创建axios实例
    this.instance = axios.create(config)
    // 保存基本信息
    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? DEFAULT_LOADING

    // 拦截器
    // 1.config传入的不同实例对应的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )

    // 2.全局拦截器
    this.instance.interceptors.request.use(
      (config: any) => {
        const loginInfoStr = localStorage.getItem('loginInfo')
        if (loginInfoStr) {
          const loginInfo = JSON.parse(loginInfoStr)
          config.headers['BDSOFT-TOKEN'] = loginInfo.token
        }
        // 携带token
        // const token = 'aaaa'//store或localStorage
        // if (token) {
        //   config.headers.Authorization = `Bearer ${token}`
        // }
        // 添加全局loading
        // if (this.showLoading) {
        //   this.loading = ElLoading.service({
        //     lock: true,
        //     background: 'rgba(0,0,0,0.5)'
        //   })
        // }
        // 参数序列化
        config.paramsSerializer = function (params: any) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }

        return config
      },
      (err) => {
        console.error(err)
        return err
      }
    )
    this.instance.interceptors.response.use(
      (res: any) => {
        if (res.data && res.data.isEncryption) {
          res.data.data =
            res.data && res.data.data ? decryptParams({ ...res.data }) : null
        }
        if (!res?.data) return ElMessage.error('系统异常，请稍后再试')
        // this.loading?.close()
        if (res.data.code === 401 || res.data.code === 501) {
          if (process.env.VUE_APP_LOGIN_TYPE === 'sso') {
            location.href = '/sso-login.html?back=' + encodeURIComponent(location.href);
          } else {
            router.push({
              path: '/login',
              query: { redirect: res.config?.params?.path }
            })
          }
          return Promise.reject(new Error(res.data))
        }
        if (res.data.code !== 200) {
          console.log('请求失败:code不等于200')
          ElMessage.error(res.data.message)
          return Promise.reject(new Error(res.data))
        }
        //直接返回data
        return res.data
      },
      (err) => {
        console.log('请求失败')
        ElMessage.error('请求失败')
        // this.loading?.close()
        // 响应失败拦截,判断HttpErrorCode显示不同错误信息
        // switch(err.response.status)
        console.error(err)
        return err
      }
    )
  }

  request<T>(config: IHttpRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 是否添加loading
      // if (config.showLoading === false) {
      //   this.showLoading = false
      // }
      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          this.showLoading = DEFAULT_LOADING
          resolve(res)
        })
        .catch((err) => {
          this.showLoading = DEFAULT_LOADING
          reject(err)
          return err
        })
    })
  }

  get<T = any>(config: IHttpRequestConfig<T>): Promise<T> {
    const url: string = config.url as string
    config.url = api[url]
    return this.request<T>({ ...config, method: 'GET' })
  }

  post<T = any>(config: IHttpRequestConfig<T>): Promise<T> {
    const url: string = config.url as string
    config.url = api[url]
    return this.request<T>({ ...config, method: 'POST' })
  }

  delete<T = any>(config: IHttpRequestConfig<T>): Promise<T> {
    const url: string = config.url as string
    config.url = api[url]
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  patch<T = any>(config: IHttpRequestConfig<T>): Promise<T> {
    const url: string = config.url as string
    config.url = api[url]
    return this.request<T>({ ...config, method: 'PATCH' })
  }
}

export default httpRequest
