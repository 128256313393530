/*
 * @Author: wangjie
 * @Date: 2022-07-14 15:51:07
 * @Description: 文件描述
 */
import { createApp } from 'vue'
import { globalRegister } from './global'

import 'normalize.css'
import './assets/css/index.less'
import './theme/style.less'

import App from './App.vue'
import router from './router'
import store from './store'
import NoData from '@/components/NoData/index.vue'
import {ElInfiniteScroll} from 'element-plus'
const app = createApp(App)

app.use(globalRegister) // 全局注册
app.use(ElInfiniteScroll) //el无限滚动指令

app.use(store)
app.use(router)
// 全局注册<暂无数据>组件
app.component('NoData',NoData)

app.mount('#app')
