/*
 * @Author: licongwei
 * @Date: 2022-04-06 18:25:48
 * @Description: 文件描述
 */
/*
 * @Author: wangjie
 * @Date: 2022-03-31 09:31:51
 * @Description: 智慧立法Api
 */
import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = { legislation: '/data/legislation/legislation.json' }
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    enforce: '/enforce/enforce'
  }
}
export default api
