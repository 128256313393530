/*
 * @Author: wangjie
 * @Date: 2022-03-07 16:58:48
 * @Description: 详情页api
 */

export default {
  downloadFile: '/detailPage/download', // 下载附件
  // detailPageList: '/detailPage/detailList', //详情页
  detailPageList: '/detailPage/detailList/v2', //详情页v2
  // detailPageRelatedLaws: '/detailPage/relatedLaws', //详情页-相关法律
  detailPageRelatedLaws: '/detailPage/relatedLaws/v2', //详情页-相关法律v2
  detailPageRelatedCase: '/case/library/relateCases', //详情页-相关案例
  // detailPageReferenceLaws: '/detailPage/referenceLaws', //详情页-引用法规
  detailPageReferenceLaws: '/detailPage/referenceLaws/v2', //详情页-引用法规v2
  detailPageKnowledgeMap: '/neo4j/getLawAndTagDatas', // 知识图谱
  lawSummary: '/detailPage/lawSummary', // 法律摘要
  // 以下为事项相关接口
  itemCount:'/matter/law/item/count',
  areaCount:'/matter/area/count',
  levelCount:'/matter/level/count',
  typeCount:'/matter/type/count',
  fieldCount:'/matter/field/count',
  itemTypeCount:'/matter/law/item/type/count',
  matterPage:'/matter/page',
  matterDetail:'/matter/detail',
  queryCountUid:'/matter/queryCountUid',
  matterCopy:'/base/powerTemp/savePowerTempFromFzzk',

}
