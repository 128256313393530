import { ElMessageBox } from 'element-plus/lib/components'
import router from '@/router'
// 监测是否处于登录状态
export const isLogin = () => process.env.VUE_APP_REQUIRE_LOGIN == 0 || !!localStorage.getItem('loginInfo')

// 跳转到对应的登录页面
export const toLogin = (fullPath?: string) => {
  if (process.env.VUE_APP_LOGIN_TYPE === 'sso') {
    location.href = '/sso-login.html?back=' + encodeURIComponent(location.href)
  } else {
    if (fullPath === undefined || fullPath === null) {
      console.log('[user.ts] toLogin 没有传入 fullPath 可能导致重新定向失败')
      fullPath = '/'
    }

    router.push({ path: '/login', query: { redirect: btoa(fullPath) } })
  }
}
// 判断角色
export function requireAnyRole(roles: any) {
  const loginInfoStr = localStorage.getItem('loginInfo')
  if (loginInfoStr) {
    const loginInfo = JSON.parse(loginInfoStr)
    let userRoles = loginInfo.user.roles || []
    let intersection = roles.filter(v => userRoles.includes(v))
    if (intersection.length > 0) {
      return true
    }
  }
  return false
}
// 跳转新页面前提示
export function checkToGo(urlObj){
  const route = router.getRoutes()?.find(i => i.name == urlObj.name)
  if(!route) return;
  if(!isLogin()) return toLogin(location.origin+route.path);
  let roles = route?.meta?.requireAnyRole
  if (!roles || requireAnyRole(roles.map(item => item.code))) {
    window.open(router.resolve(urlObj).href, '_blank')
  }else{
    ElMessageBox.alert('当前账号无相应功能权限，请联系管理员', '提示', {
      type: 'warning',
      confirmButtonText: '确认'
    })
  }
}