/*
 * @FileDescription: 行政案例列表页Api
 * @Author: zhaohongyang
 * @Date: 2022-03-02
 */
import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = {}
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    searchCaseListTotalList: '/case/searchCaseListPage/totalList', //案例列表页-总体数量
    searchCaseListLawList: '/case/searchCaseListPage/lawList', //案例列表页-列表
    searchJudgeList:'/judgment/search', // 裁决文书-列表
    searchJudgeDetail:'/judgment/detail', //裁决文书-详情
    relateLaws:'/judgment/relateLaws' //裁决文书-关联法律
  }
}
export default api
