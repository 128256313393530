// nginx
// location /prod-api {
//     proxy_pass http://192.168.2.46:8082/fzzyk/;
// }
const BASE_URL = process.env.VUE_APP_BASE_API
const FZZYK_URL = process.env.VUE_APP_FZZYK_API
const LEGISLATE_URL = process.env.VUE_APP_LEGISLATE_API
const SUPERVISE_URL = '/supervise-api'
const TIME_OUT = 6000
export {BASE_URL, FZZYK_URL, LEGISLATE_URL, SUPERVISE_URL, TIME_OUT}
