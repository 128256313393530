/*
 * @Author: lailifang
 * @Date: 2022-03-03 09:35:40
 * @Description: 路由
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { fzzykRequest } from '@/service'
import { requireAnyRole } from '@/utils/permission'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '法治智库' },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login/index.vue')
  },
  {
    path: '/Models',
    name: 'Models',
    meta: { title: '法治大模型' },
    component: () => import(/* webpackChunkName: "Models" */ '@/views/StaticPage/LawRuleModels/index.vue')
  },
  {
    path: '/fuyiTool/chat',
    name: 'FuyiToolChat',
    meta: { title: '法治知识问答' },
    component: () => import(/* webpackChunkName: "FuyiToolChat" */ '@/views/fuyiTool/chat/index.vue')
  },
  {
    path: '/fuyiTool/extraction',
    name: 'FuyiToolExtraction',
    meta: { title: '复议要素提取' },
    component: () => import(/* webpackChunkName: "FuyiToolExtraction" */ '@/views/fuyiTool/extraction/index.vue')
  },
  {
    path: '/stipulateTopic',
    name: 'stipulateTopic',
    meta: { title: '领导干部应知应会党内法规和国家法规' },
    component: () => import(/* webpackChunkName: "stipulateTopic" */ '@/views/StaticPage/StipulateTopic/index.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "RootView" */ '@/RootView.vue'),
    children: [
      {
        path: '/Layout',
        component: () => import(/* webpackChunkName: "Layout" */ '@/views/Layout/index.vue'),
        redirect: '/Home',
        children: [
          {
            path: '/Home',
            name: 'Home',
            meta: { id: 0 },
            component: () => import(/* webpackChunkName: "Home" */ '@/views/Layout/Home/index.vue')
          },
          {
            path: '/SearchList',
            name: 'SearchList',
            meta: { title: '法律法规', id: 1 },
            component: () => import(/* webpackChunkName: "SearchList" */ '@/views/Layout/SearchList/index.vue')
          },
          {
            path: '/SearchCaseList',
            name: 'SearchCaseList',
            meta: { title: '行政案例', id: 2 },
            component: () => import(/* webpackChunkName: "SearchCaseList" */ '@/views/Layout/SearchCaseList/index.vue')
          },
          {
            path: '/caseLibrary',
            name: 'CaseLibrary',
            meta: { title: '司法案例', id: 4 },
            component: () => import(/* webpackChunkName: "CaseLibrary" */ '@/views/Layout/CaseLibrary/index.vue')
          },
          {
            path: '/SearchLawList',
            name: 'SearchLawList',
            meta: { title: '裁判文书', id: 3 },
            component: () => import(/* webpackChunkName: "SearchLawList" */ '@/views/Layout/SearchLowList/index.vue')
          },
          {
            path: '/SearchFyList',
            name: 'SearchFyList',
            meta: { title: '复议案例', id: 5 },
            component: () => import(/* webpackChunkName: "SearchFyList" */ '@/views/Layout/SearchFyList/index.vue')
          }
        ]
      },
      {
        path: '/BrowsHistory',
        meta: { title: '浏览历史' },
        component: () => import(/* webpackChunkName: "BrowsHistory" */ '@/views/BrowsHistory/index.vue')
      },
      {
        path: '/BrowsHistoryCase',
        meta: { title: '浏览历史', id: 2 },
        component: () => import(/* webpackChunkName: "BrowsHistoryCase" */ '@/views/BrowsHistoryCase/index.vue')
      },
      {
        path: '/DetailPage',
        name: 'DetailPage',
        meta: { title: '全文阅读', id: 1, requireAnyRole: [{ code: 'detailLaw', name: '法律法规' }] },
        component: () => import(/* webpackChunkName: "DetailPage" */ '@/views/DetailPage/index.vue')
      },
      {
        path: '/DetailCasePage',
        name: 'DetailCasePage',
        meta: { title: '全文阅读', id: 2, requireAnyRole: [{ code: 'detailAdministrativeCase', name: '行政案例' }] },
        component: () => import(/* webpackChunkName: "DetailCasePage" */ '@/views/DetailCasePage/index.vue')
      },
      {
        path: '/CaseLibraryDetail',
        name: 'CaseLibraryDetail',
        meta: { title: '全文阅读', id: 4, requireAnyRole: [{ code: 'detailJudicialCase', name: '司法案例' }] },
        component: () => import(/* webpackChunkName: "CaseLibraryDetail" */ '@/views/Layout/CaseLibrary/DetailCasePage/index.vue')
      },
      {
        path: '/DetailCasePageV2',
        name: 'DetailCasePageV2',
        meta: { title: '全文阅读', id: 3, requireAnyRole: [{ code: 'detailJudgmentDoc', name: '裁判文书' }] },
        component: () => import(/* webpackChunkName: "DetailCasePageV2" */ '@/views/DetailCasePage/indexV2.vue')
      },
      {
        path: '/FyCaseDetail',
        name: 'FyCaseDetail',
        meta: { title: '全文阅读', id: 5, requireAnyRole: [{ code: 'detailReconsiderCase', name: '复议案例' }] },
        component: () => import(/* webpackChunkName: "FyCaseDetail" */ '@/views/Layout/SearchFyList/DetailCasePage/index.vue')
      },
      // {
      //   path: '/KnowledgeMap',
      //   name: 'KnowledgeMap',
      //   meta: { title: '法律图谱' },
      //   component: () => import(/* webpackChunkName: "KnowledgeMap" */ '@/views/KnowledgeMap/index.vue')
      // },
      {
        path: '/MyCollect',
        meta: { title: '我的收藏' },
        component: () => import(/* webpackChunkName: "MyCollect" */ '@/views/MyCollect/index.vue')
      },
      {
        path: '/MyFeedback',
        meta: { title: '我的反馈' },
        component: () => import(/* webpackChunkName: "MyFeedback" */ '@/views/MyFeedback/index.vue')
      },
      {
        path: '/AboutUs',
        meta: { title: '关于我们', noSideMenu: true },
        component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/AboutUs/index.vue')
      },
      {
        path: '/GovernByLaw',
        meta: { title: '依法治省（市）', noSideMenu: true },
        component: () => import(/* webpackChunkName: "GovernByLaw" */ '@/views/StaticPage/GovernByLaw/index.vue')
      },
      {
        path: '/WLegislation',
        meta: { title: '智慧立法', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WLegislation" */ '@/views/StaticPage/WLegislation/index.vue')
      },
      {
        path: '/WEnforce',
        meta: { title: '智慧执法', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WEnforce" */ '@/views/StaticPage/WEnforce/index.vue')
      },
      {
        path: '/WSupervision',
        meta: { title: '智慧监督', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WSupervision" */ '@/views/StaticPage/WSupervision/index.vue')
      },
      {
        path: '/WReconsideration',
        meta: { title: '智慧复议', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WReconsideration" */ '@/views/StaticPage/WReconsideration/index.vue')
      },
      {
        path: '/LegalServices',
        meta: { title: '公共法律服务', noSideMenu: true },
        component: () => import(/* webpackChunkName: "LegalServices" */ '@/views/StaticPage/LegalServices/index.vue')
      },
      {
        path: '/DataCenter',
        name: 'DataCenter',
        meta: { title: '数据中台', noSideMenu: true },
        component: () => import(/* webpackChunkName: "DataCenter" */ '@/views/StaticPage/DataCenter/index.vue')
      },
      {
        path: '/KnowledgeCenter',
        name: 'KnowledgeCenter',
        meta: { title: '知识中台', noSideMenu: true },
        component: () => import(/* webpackChunkName: "KnowledgeCenter" */ '@/views/StaticPage/KnowledgeCenter/index.vue')
      },
      {
        path: '/',
        redirect: {
          path: '/Layout'
        }
      },
      {
        path: '/Echarts',
        name: 'Echarts',
        meta: { title: '图表', id: 4, echarts: true },
        component: () => import(/* webpackChunkName: "Echarts" */ '@/views/echarts/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const title = document.getElementsByTagName('title')[0]
  title.innerHTML = (to.meta?.title as string) || '法治智库'
  if (process.env.VUE_APP_REQUIRE_LOGIN == 1) {
    // 若登录状态已失效则清除右上角用户信息
    fzzykRequest.get({ url: 'loginCheckAlways200' }).then(async (res) => {
      if (!res.data.isLogin) {
        // 未登录清除登录信息
        localStorage.removeItem('loginInfo')
        sessionStorage.removeItem('loginInfo')
        if (process.env.VUE_APP_LOGIN_TYPE !== 'sso') {
          await fzzykRequest.get({ url: 'logout' })
        }
      }
      if (
        [
          '/fuyiTool/chat',
          '/fuyiTool/extraction',
          '/SearchList',
          '/SearchCaseList',
          '/CaseLibrary',
          '/SearchLawList',
          '/SearchFyList',
          '/GovernByLaw',
          '/WLegislation',
          '/WEnforce',
          '/WSupervision',
          '/WReconsideration',
          '/LegalServices',
          '/DataCenter',
          '/KnowledgeCenter',
          '/login',
          '/Home',
          '/AboutUs',
          '/Models'
        ].includes(to.path)
      ) {
        next()
        return
      }
      fzzykRequest.get({ url: 'systemLoginCheck', params: { path: btoa(to.fullPath) } }).then((res) => {
        if (to.meta && to.meta.requireAnyRole && to.meta.requireAnyRole.length > 0) {
          if (requireAnyRole((to.meta.requireAnyRole || []).map((item) => item.code))) {
            next()
          } else {
            // 没有角色回到首页
            if (from.path === '/' || from.path === '/login') {
              ElMessageBox.alert('当前账号无相应功能权限，请联系管理员', '提示', {
                type: 'warning',
                confirmButtonText: '确认',
                callback: () => {
                  window.close()
                }
              })
            } else {
              ElMessage.warning('当前账号无相应功能权限，请联系管理员')
              next({ name: 'Home' })
            }
          }
        } else {
          next()
        }
      })
    })
  } else {
    next()
  }
})

export default router
