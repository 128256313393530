/**
 * @Author: zyp
 * @Date: 2023-04-12 08:56:53
 * @LastEditors: zyp
 * @LastEditTime: 2023-12-04 19:12:22
 * @FilePath: \zfjd_cp_front\common\utils\Encryption\AES_ECB.js
 */
import CryptoJS from './crypto-js'

// 获取key，
export function genKey(length = 16) {
  const random = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let str = ''
  for (let i = 0; i < length; i++) {
    str = str + random.charAt(Math.random() * random.length)
  }
  return str
}

// 加密
export function encrypt(plaintext, key) {
  if (plaintext instanceof Object) {
    // JSON.stringify
    plaintext = JSON.stringify(plaintext)
  }
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

// 解密
export function decrypt(ciphertext, key) {
  const decrypt = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  let decString = CryptoJS.enc.Utf8.stringify(decrypt)
  if (decString.charAt(0) === '{' || decString.charAt(0) === '[') {
    // JSON.parse
    decString = JSON.parse(decString)
  }
  return decString
}
