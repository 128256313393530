import { JSEncrypt } from './JSEncrypt.js'
import { decrypt, encrypt, genKey } from './AES_ECB'

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGIkNT+SqTByhq//OvKOgLUDJRF3tc/l6GlPkPw2qjUk5uP2NESSvXkKwYYOhux8/u6NaAy5Aks4IabVB/2S/XPdz+g5qJFsBYVXhvvCEprc4ppftIiLKXDbO+w+sPX8XxYNbcrN5H8a+K2ZnXo4cPk6/NQ/nWwpEUwC+o35mElQIDAQAB'
const privateKey = 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAMPdBkwRnuY0uqzVjhFuJ8FbQKjMZekBDoFq3Rt37HS8wYw+tX20jApWdfyobprYUJp9OqUjrr+ScT5Isd19TfAxBth0YXCWMQOLQiWwB17SEgbo+2xwivKDqtb3E4kMZrwMCJf6aLfJkDhq8X/ZKF+WiE8BCugT27+N/9MjC9W7AgMBAAECgYEAv4YhAz9zxx4msJ0/B/JUc7Hcv3Ln8Hl1Cr5JqfORx/FPvekQJfTnv7AePcsiJM6Dux3bu+HxnikADWyeaLx/+TfIbnBIV2w6lj4oMv893Zao95Wp5IwwVe1hh06b7JFagkb+BC4fM+oFTs/n/igSK33QkQRGkLOObFZj8Z//U6kCQQD7b2cwfuNgzusudwjShEX4BIP1dN7crnysDysMNevrZLDeH2VLMp1t7KtTUqeI+Y1v5mzSjasXjIy7GsfJmw8tAkEAx2tXErcUGV5MXtQ1Q1t9iC3mR+RCfjPQ2r8Aewd/WO3tiF+k3DfRCNHqC0DyB+K3OgrXPqhMEKhokbVJpp9JhwJAas0xadocP3zlTUFmz/U7u1Q/sT3JhLfhYTy4LmGhKsYEkvWHE2AWmyDSzXvoijhmqceZJf7jVAdxp/CCONLSqQJAA6Ol1yWYLE5YPnLI9g25YL7VsQItZHg1m4hYzeX5IPpWl2KHc61hFzQdfU8WG6a7iUehXfdcTAGGxwHz8iJ+CQJAdAxrxSUBqkTwnYmdf1S8G5EMHqQncSVsjZEkwOUgEhPcazGHP7467w2ZMx33N0RMw98Ujg5o8JqvbUfxB9c1nw=='

const encryptedType = 'AES'

/**
 * 加密方法
 * @param encryptedStr
 * @returns {string}
 */
export function encryptedData(encryptedStr) {
  encryptedStr = window.btoa(encryptedStr)
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey) // 公钥
  // 将加密的数据转码为base64
  return encrypt.encryptLong(encryptedStr) // 加密后的数据
}

/**
 * 解密方法
 * @param encryptedStr
 * @returns {string|boolean|undefined}
 */
export function decryptData(encryptedStr) {
  const encrypt = new JSEncrypt()
  encrypt.setPrivateKey(privateKey) // 私钥
  // password要解密的数据 先转为byte数组在进行解码
  return window.atob(encrypt.decryptLong(encryptedStr)) // 解密后的数据
}


// 加密
export function encryptedParams(params) {
  if (encryptedType === 'ESA') {
    return {
      encryptedData: encryptedData(params)
    }
  } else {
    const aesKey = genKey(16)
    return {
      encryptedSecret: encryptedData(aesKey),
      encryptedData: encrypt(params, aesKey)
    }
  }
}

// 解密
/**
 * @description: 解密
 * @param {*} params
 * @return {*} 解密后的数据
 */
export function decryptParams(params) {
  let key = ''
  let res = ''
  if (params.encryptKey) {
    key = decryptData(params.encryptKey)
    res = decrypt(params.data, key)
  }
  return res
}