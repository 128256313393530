/*
 * @Author: lailifang
 * @Date: 2022-03-03 09:35:40
 * @Description: 路由
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { fzzykRequest } from '@/service'
import { requireAnyRole } from '@/utils/permission'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '法治智库' },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login/index.vue')
  },
  {
    path: '/Models',
    name: 'Models',
    meta: { title: '法治大模型' },
    component: () => import(/* webpackChunkName: "Models" */ '@/views/StaticPage/LawRuleModels/index.vue')
  },
  {
    path: '/fuyiTool/chat',
    name: 'FuyiToolChat',
    meta: { title: '法治知识问答' },
    component: () => import(/* webpackChunkName: "FuyiToolChat" */ '@/views/fuyiTool/chat/index.vue')
  },
  {
    path: '/fuyiTool/extraction',
    name: 'FuyiToolExtraction',
    meta: { title: '复议要素提取' },
    component: () => import(/* webpackChunkName: "FuyiToolExtraction" */ '@/views/fuyiTool/extraction/index.vue')
  },
  {
    path: '/StipulateTopic',
    name: 'StipulateTopic',
    meta: { title: '领导干部应知应会党内法规和国家法规' },
    component: () => import(/* webpackChunkName: "StipulateTopic" */ '@/views/StaticPage/StipulateTopic/index.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "RootView" */ '@/RootView.vue'),
    children: [
      {
        path: '/Layout',
        component: () => import(/* webpackChunkName: "Layout" */ '@/views/Layout/index.vue'),
        redirect: '/Home',
        children: [
          {
            path: '/Home',
            name: 'Home',
            meta: { id: 0 },
            component: () => import(/* webpackChunkName: "Home" */ '@/views/Layout/Home/index.vue')
          },
          {
            path: '/SearchList',
            name: 'SearchList',
            meta: { title: '法律法规', id: 1 },
            component: () => import(/* webpackChunkName: "SearchList" */ '@/views/Layout/SearchList/index.vue')
          },
          {
            path: '/SearchCaseList',
            name: 'SearchCaseList',
            meta: { title: '行政案例', id: 2 },
            component: () => import(/* webpackChunkName: "SearchCaseList" */ '@/views/Layout/SearchCaseList/index.vue')
          },
          {
            path: '/caseLibrary',
            name: 'CaseLibrary',
            meta: { title: '司法案例', id: 4 },
            component: () => import(/* webpackChunkName: "CaseLibrary" */ '@/views/Layout/CaseLibrary/index.vue')
          },
          {
            path: '/SearchLawList',
            name: 'SearchLawList',
            meta: { title: '裁判文书', id: 3 },
            component: () => import(/* webpackChunkName: "SearchLawList" */ '@/views/Layout/SearchLowList/index.vue')
          },
          {
            path: '/SearchFyList',
            name: 'SearchFyList',
            meta: { title: '复议案例', id: 5 },
            component: () => import(/* webpackChunkName: "SearchFyList" */ '@/views/Layout/SearchFyList/index.vue')
          }
        ]
      },
      {
        path: '/BrowsHistory',
        meta: { title: '浏览历史' },
        component: () => import(/* webpackChunkName: "BrowsHistory" */ '@/views/BrowsHistory/index.vue')
      },
      {
        path: '/BrowsHistoryCase',
        meta: { title: '浏览历史', id: 2 },
        component: () => import(/* webpackChunkName: "BrowsHistoryCase" */ '@/views/BrowsHistoryCase/index.vue')
      },
      {
        path: '/DetailPage',
        name: 'DetailPage',
        meta: { title: '全文阅读', id: 1, requireAnyRole: [{ code: 'detailLaw', name: '法律法规' }] },
        component: () => import(/* webpackChunkName: "DetailPage" */ '@/views/DetailPage/index.vue')
      },
      {
        path: '/DetailCasePage',
        name: 'DetailCasePage',
        meta: { title: '全文阅读', id: 2, requireAnyRole: [{ code: 'detailAdministrativeCase', name: '行政案例' }] },
        component: () => import(/* webpackChunkName: "DetailCasePage" */ '@/views/DetailCasePage/index.vue')
      },
      {
        path: '/CaseLibraryDetail',
        name: 'CaseLibraryDetail',
        meta: { title: '全文阅读', id: 4, requireAnyRole: [{ code: 'detailJudicialCase', name: '司法案例' }] },
        component: () => import(/* webpackChunkName: "CaseLibraryDetail" */ '@/views/Layout/CaseLibrary/DetailCasePage/index.vue')
      },
      {
        path: '/DetailCasePageV2',
        name: 'DetailCasePageV2',
        meta: { title: '全文阅读', id: 3, requireAnyRole: [{ code: 'detailJudgmentDoc', name: '裁判文书' }] },
        component: () => import(/* webpackChunkName: "DetailCasePageV2" */ '@/views/DetailCasePage/indexV2.vue')
      },
      {
        path: '/FyCaseDetail',
        name: 'FyCaseDetail',
        meta: { title: '全文阅读', id: 5, requireAnyRole: [{ code: 'detailReconsiderCase', name: '复议案例' }] },
        component: () => import(/* webpackChunkName: "FyCaseDetail" */ '@/views/Layout/SearchFyList/DetailCasePage/index.vue')
      },
      // {
      //   path: '/KnowledgeMap',
      //   name: 'KnowledgeMap',
      //   meta: { title: '法律图谱' },
      //   component: () => import(/* webpackChunkName: "KnowledgeMap" */ '@/views/KnowledgeMap/index.vue')
      // },
      {
        path: '/MyCollect',
        meta: { title: '我的收藏' },
        component: () => import(/* webpackChunkName: "MyCollect" */ '@/views/MyCollect/index.vue')
      },
      {
        path: '/MyFeedback',
        meta: { title: '我的反馈' },
        component: () => import(/* webpackChunkName: "MyFeedback" */ '@/views/MyFeedback/index.vue')
      },
      {
        path: '/AboutUs',
        meta: { title: '关于我们', noSideMenu: true },
        component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/AboutUs/index.vue')
      },
      {
        path: '/CXYY',
        name: 'CXYY',
        meta: { title: '智慧法治创新应用', noSideMenu: true },
        component: () => import(/* webpackChunkName: "KnowledgeCenter" */ '@/views/PublicityPage/index.vue')
      },
      {
        path: '/GovernByLaw',
        meta: { title: '依法治省（市）', noSideMenu: true },
        component: () => import(/* webpackChunkName: "GovernByLaw" */ '@/views/StaticPage/GovernByLaw/index.vue')
      },
      {
        path: '/WLegislation',
        meta: { title: '智慧立法', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WLegislation" */ '@/views/StaticPage/WLegislation/index.vue')
      },
      {
        path: '/WEnforce',
        meta: { title: '智慧执法', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WEnforce" */ '@/views/StaticPage/WEnforce/index.vue')
      },
      {
        path: '/WSupervision',
        meta: { title: '智慧监督', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WSupervision" */ '@/views/StaticPage/WSupervision/index.vue')
      },
      {
        path: '/WReconsideration',
        meta: { title: '智慧复议', noSideMenu: true },
        component: () => import(/* webpackChunkName: "WReconsideration" */ '@/views/StaticPage/WReconsideration/index.vue')
      },
      {
        path: '/LegalServices',
        meta: { title: '公共法律服务', noSideMenu: true },
        component: () => import(/* webpackChunkName: "LegalServices" */ '@/views/StaticPage/LegalServices/index.vue')
      },
      {
        path: '/DataCenter',
        name: 'DataCenter',
        meta: { title: '数据中台', noSideMenu: true },
        component: () => import(/* webpackChunkName: "DataCenter" */ '@/views/StaticPage/DataCenter/index.vue')
      },
      {
        path: '/KnowledgeCenter',
        name: 'KnowledgeCenter',
        meta: { title: '知识中台', noSideMenu: true },
        component: () => import(/* webpackChunkName: "KnowledgeCenter" */ '@/views/StaticPage/KnowledgeCenter/index.vue')
      },
      {
        path: '/',
        redirect: {
          path: '/Layout'
        }
      },
      {
        path: '/Echarts',
        name: 'Echarts',
        meta: { title: '图表', id: 4, echarts: true },
        component: () => import(/* webpackChunkName: "Echarts" */ '@/views/echarts/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const title = document.getElementsByTagName('title')[0]
  title.innerHTML = (to.meta?.title as string) || '法治智库'
  // 设置SEO meta内容
  const keywordsJson = {
    '/Home':'法治智库,法治大模型,法律法规库,复议案例库,依法治省,智慧立法,智慧执法,智慧监督,智慧复议,智慧公服',
    '/AboutUs':'法治智库,法治大模型,法律法规库,复议案例库,依法治省,智慧立法,智慧执法,智慧监督,智慧复议,智慧公服',
    '/GovernByLaw':'依法治省,依法治市,法治督查,法治调研,法律顾问,考核评估,习近平法治思想,依法决策 依法行政 依法管理 依法办事',
    '/WLegislation':'法律法规规章库,辅助起草,版本对照,意见处理,辅助审查,辅助清理,规范性文件,合法性审核,文件清理,备案审查',
    '/WEnforce':'行政执法,智能执法,镇街执法,综合执法,领域执法,非现场执法,离线执法,电子亮证,入企扫码,预警监控,智能推荐,全息画像,证照识别,优化营商环境,三项制度,行政检查,行政处罚,行政强制,指挥调度,勤务巡查,电子送达,重大案件法制审核,电子案卷归档,证据固化',
    '/WSupervision':'行政执法监督,四级监督体系,事前监督,事后监督,执法要素管理,组织队伍管理,执法事项管理,资格证件管理,执法证件管理,执法行为监督,智能预警,智能案卷评查,执法效能评价,执法评议考核,监督案件办理',
    '/WReconsideration':'行政复议,行政应诉,智能复议,智能听证,复议一体机,智能归档,复议办案系统,复议听证系统',
    '/DataCenter':'大数据综合管理平台,数据汇聚治理,治理任务监控,数据质量管理,数据可视化,分析指标管理,数据资产管理,数据共享服务,数据源整合,多源异构',
    '/KnowledgeCenter':'要素提取,概要生成,敏感词识别,文本分类,相似度计算,OCR识别,实体抽取,事件抽取,案例推荐,案由识别,印章检测,签字检测',
    '/CXYY':'智能立法辅助,规范性文件管理平台,行政执法入企扫码,优化营商环境,行政执法智能组卷,执法事项管理,智能案卷评查,智慧复议一体机,复议会议系统,复议听证系统',
    '/SearchList':'法律法规智能检索,法律法规库,行政法规库,部门规章库,地方性法规库,地方政府规章库,规范性文件库,政策性文件库,执法事项库',
    '/DetailPage':'法律法规智能检索,法律法规库,行政法规库,部门规章库,地方性法规库,地方政府规章库,规范性文件库,政策性文件库,执法事项库,英文版,历史沿革,上位法,引用法规,相关法规',
    '/CaseLibrary':'司法案例库,司法案例智能检索,指导性案例库,参考案例库,审理法院,审理程序',
    '/CaseLibraryDetail':'司法案例库,司法案例智能检索,指导性案例库,参考案例库,基本案情,司法案例智能检索,法律引用,裁判要点,裁判结果,裁判理由',
    '/SearchFyList':'复议案例库,复议案例智能检索,复议决定,行政复议决定书',
    '/FyCaseDetail':'复议案例库,复议案例智能检索,复议决定,行政复议决定书,法律引用',
    '/StipulateTopic':'领导干部应知应会党内法规和国家法规,法治智库,法律法规库,法律法规智能检索,党内法规,法律,行政法规,监察法规',
  }
  const meta = document.querySelector('meta[name=keywords]')
  if(meta){
    meta.setAttribute('content',keywordsJson[to.path] || keywordsJson['/Home'])
  }
  const descriptionJson = {
    '/Home':'卓越的智慧法治解决方案服务商、智能法律服务供应商。基于全面、精准、高效的法律法规等法治资源库，利用大模型技术，为依法治省、立法、执法、监督、复议、公共法律服务等领域提供智能化法律服务。',
    '/AboutUs':'卓越的智慧法治解决方案服务商、智能法律服务供应商。基于全面、精准、高效的法律法规等法治资源库，利用大模型技术，为依法治省、立法、执法、监督、复议、公共法律服务等领域提供智能化法律服务。',
    '/GovernByLaw':'构建了依法治省、依法治市运行监测体系、评价指标体系、决策支撑体系，有效督促、指导、评估依法治省、依法治市各项任务顺利开展，保障各项任务有效落地，为全面提升依法治省、依法治市工作治理体系和治理能力现代化水平提供技术支撑，为全面提高法治政府法治社会建设提供智能支持',
    '/WLegislation':'依托法治资源库，基于深度学习的法治大模型实现业务场景化，打造 1+1+5 的伴随式、组件式智慧立法，提供一站式智能辅助，创新智慧立法新模式，助力科学立法、民主立法、依法立法体系建设。',
    '/WEnforce':'基于法治大模型，依托大数据、云技术、区块链等技术，贴合三项制度指导意见、行政执法质量三年行动计划等政策背景，构建面向省、市、县、乡四级行政执法部门的数字化智慧执法平台，全面推进严格规范公正文明执法。',
    '/WSupervision':'专业化、智能化的行政执法监督平台，全面覆盖行政执法监督业务场景，支撑四级行政执法协调监督工作体系建设，实现对行政执法工作的全方位、全流程、常态化、长效化监督。',
    '/WReconsideration':'全面覆盖行政复议与应诉业务场景，利用大数据、人工智能等信息化技术，面向各级行政复议机关、应诉机关和社会公众，提供网上复议申请，案件全流程办理，全过程数据分析等服务，实现了复议便民化、办案智能化、决策数据化。',
    '/DataCenter':'平台从汇、治、管、用四方面，构建数据全面融合、全面管控、标准统一、服务统一、多方复用的综合数据主题库，实现对多源异构各类法治相关数据进行采集、存储、处理、融合、治理，形成全面汇聚、高效流通、按需共享、深度利用的法治数据资产，为业务延伸、智能化服务、大数据分析服务、数据共享服务提供支撑，为各类法治业务应用提供赋能底座',
    '/KnowledgeCenter':'大幅提升文本信息处理效率，全流程语料标注模型训练服务，自动化构建数字业务信息体系，大力发展数字法治科技新动能，为数字法治政府建设提供新质生产力。',
    '/CXYY':'智能立法辅助提供伴随式、组件式智慧立法辅助工具，创新智慧立法新模式，助力科学立法、民主立法、依法立法体系建设。规范性文件管理平台支持合法性审核、备案审查、文件清理全流程在线办理，支持三级应用，助力文件制发规范化、流程化、电子化，全面保障规范性文件管理法治化水平。行政执法入企扫码实现涉企检查信息统筹整合，支撑扰企执法整治工作的开展，减轻经营主体压力，推动法治化营商环境高质量发展。行政执法智能组卷支持电子案卷一键扫描、一键识别、一键生成、一键归档，实现案卷管理标准化、文书存储电子化、案件信息结构化、数据上报自动化。执法事项管理支撑执法部门、监督部门全面、规范、动态的管理执法事项。智能案卷评查支持行政执法案卷全过程在线评查，有效提升评查效率、提高评查质量，实现案卷评查电子化、便捷化、智能化。智慧复议一体机提供24小时自助服务，方便群众表达复议诉求，维护合法权益。复议会议系统提供PC、移动多端应用，支持与办案系统关联，复议会议线上线下结合，安全、智能、便捷、高效。',
    '/SearchList':'提供专业、全面、便捷的法律资源检索服务，支持按照法律、行政法规、部门规章、地方性法规、地方政府规章、规范性文件、政策性文件效力级别聚合检索，支持按照标题、全文、关键词精准及模糊检索',
    '/DetailPage':'提供法律法规标准版、纯净版、事项版、英文版多种模式浏览展示 ，提供法规历史沿革、上下位法关联、相关法关联、引用法规、编章节条款项等信息查看',
    '/CaseLibrary':'提供专业、全面、便捷的司法检索服务，支持按照指导性案例、参考案例聚合检索，支持按照标题、全文精准及模糊检索',
    '/CaseLibraryDetail':'提供行司法案例全文展示，提供相关案例、法律引用等相关信息展示',
    '/SearchFyList':'提供专业、便捷的复议检索服务，支持按照复议决定类型、复议时间聚合检索，支持按照标题、全文精准及模糊检索',
    '/FyCaseDetail':'提供行政复议决定书全文展示，提供相关案例、法律引用等相关信息展示',
    '/StipulateTopic':' ',
  }
  const meta2 = document.querySelector('meta[name=description]')
  if(meta2){
    meta2.setAttribute('content',descriptionJson[to.path] || descriptionJson['/Home'])
  }
  if (process.env.VUE_APP_REQUIRE_LOGIN == 1) {
    // 若登录状态已失效则清除右上角用户信息
    fzzykRequest.get({ url: 'loginCheckAlways200' }).then(async (res) => {
      if (!res.data.isLogin) {
        // 未登录清除登录信息
        localStorage.removeItem('loginInfo')
        sessionStorage.removeItem('loginInfo')
        if (process.env.VUE_APP_LOGIN_TYPE !== 'sso') {
          await fzzykRequest.get({ url: 'logout' })
        }
      }
      if (
        [
          '/fuyiTool/chat',
          '/fuyiTool/extraction',
          '/SearchList',
          '/SearchCaseList',
          '/CaseLibrary',
          '/SearchLawList',
          '/SearchFyList',
          '/GovernByLaw',
          '/WLegislation',
          '/WEnforce',
          '/WSupervision',
          '/WReconsideration',
          '/LegalServices',
          '/DataCenter',
          '/KnowledgeCenter',
          '/login',
          '/Home',
          '/AboutUs',
          '/StipulateTopic',
          '/Models',
          '/CXYY'
        ].includes(to.path)
      ) {
        next()
        return
      }
      fzzykRequest.get({ url: 'systemLoginCheck', params: { path: btoa(to.fullPath) } }).then((res) => {
        if (to.meta && to.meta.requireAnyRole && to.meta.requireAnyRole.length > 0) {
          if (requireAnyRole((to.meta.requireAnyRole || []).map((item) => item.code))) {
            next()
          } else {
            ElMessage.warning('当前账号无相应功能权限，请联系管理员')
            next({ name: 'Home' })
          }
        } else {
          next()
        }
      })
    })
  } else {
    next()
  }
})

export default router
