/*
 * @Author: lailifang
 * @Date: 2022-03-05 14:58:06
 */

import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = {
    browsingHistoryTable: '/data/Browsing/historyTable' // 浏览历史-列表
  }
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    // browsingHistoryTable: '/BrowseHistoryLaw/queryByName', // 法律浏览历史-列表
    browsingHistoryTable: '/BrowseHistoryLaw/queryByName/v2', // 法律浏览历史-列表v2
    browsingHistoryTable2: '/case/BrowseHistoryLaw/queryByName', // 行政案例浏览历史-列表
    browsingHistoryTable3: '/caseLibrary/history/getCaseLibraryHistorys', // 司法案例浏览历史-列表
    browsingHistoryTable4: '/judgment/history/getJudgmentHistorys' // 裁判文书浏览历史-列表
  }
}
export default api
