/*
 * @Author: wangjie
 * @Date: 2022-04-06 11:27:39
 * @Description: 智慧监督Api
 */
import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = {}
  // 接口 （地址在vue.config.js配置）
} else {
  api = { supervision: '/supervision/supervision' }
}
export default api
